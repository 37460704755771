
import { defineComponent, ref, watch } from 'vue'
import httpRequest from '@/service'
import LazyPanel from '../components/MainView/LazyPanel.vue'
import { ElMessage } from 'element-plus/lib/components'

export default defineComponent({
  emits: ['match-link'],
  props: {
    type: {
      type: String,
      default: 'law'
    },
    currentID: {
      type: [String, Number],
      default: ''
    }
  },
  components: { LazyPanel },
  setup(props: any, { emit }) {
    interface ILaws {
      [key: string]: any
    }

    // 跳转立法工具
    const tools = [
      {
        text: '智能辅助起草',
        img: 'column1-bg.png',
        link: process.env.VUE_APP_LEGISLATE_URL + '/assist-draft',
        // link: '',
        target: '_blank'
      },
      {
        text: '智能版本对照',
        img: 'column2-bg.png',
        link: process.env.VUE_APP_LEGISLATE_URL + '/version-compare-v2',
        // link: '',
        target: '_blank'
      },
      {
        text: '书面意见汇总',
        img: 'column3-bg.png',
        link: process.env.VUE_APP_LEGISLATE_URL + '/opinion-collect',
        // link:'',
        target: '_blank'
      },
      {
        text: '公开意见处理',
        img: 'column4-bg.png',
        link: process.env.VUE_APP_LEGISLATE_URL + '/opinion-handle',
        // link: '',
        target: '_blank'
      },
      {
        text: '智能辅助审查',
        img: 'column5-bg.png',
        link: process.env.VUE_APP_LEGISLATE_URL + '/assist-examine',
        // link:'',
        target: '_blank'
      }
    ]
    const defaultValue = ref(5)
    // 相关案例
    const RelatedLaws = ref<ILaws>([])
    const RelatedLawsTotal = ref(0)
    const curPage1 = ref(1)
    const RelatedLawsApi = (nums: number) => {
      if (props.type === 'law') {
        httpRequest
          .post({
            url: 'detailPageRelatedLaws',
            params: { id: props.currentID, nums: nums, page: curPage1.value }
          })
          .then((res) => {
            if (RelatedLaws.value.length == 0) {
              RelatedLaws.value = res.data.list
              RelatedLawsTotal.value = res.data.total
            } else {
              res.data.list.forEach((item: any) => {
                RelatedLaws.value.push(item)
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else if (props.type === 'caseLibrary') {
        httpRequest
          .post({
            url: 'detailPageRelatedCase',
            params: { id: props.currentID }
          })
          .then((res) => {
            RelatedLaws.value = res.data
            RelatedLawsTotal.value = res.data.length
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
    const relateMoreClick = () => {
      curPage1.value++
      RelatedLawsApi(defaultValue.value * 2)
    }

    //引用法规
    const referenceLaws = ref<ILaws>([])
    const referenceLawsTotal = ref(0)
    const curPage2 = ref(1)
    const referenceLawsApi = (nums: number) => {
      if (props.type === 'law') {
        httpRequest
          .post({
            url: 'detailPageReferenceLaws',
            params: { id: props.currentID, nums: nums, page: curPage2.value }
          })
          .then((res) => {
            if (referenceLaws.value.length == 0) {
              referenceLaws.value = res.data.list
              referenceLawsTotal.value = res.data.total
            } else {
              res.data.list.forEach((item: any) => {
                referenceLaws.value.push(item)
              })
            }
            emit('match-link', referenceLaws.value)
          })
          .catch((err) => {
            console.log(err)
          })
      } else if (props.type === 'caseLibrary') {
        httpRequest.post({ url: 'caseLibraryRelateLaws', params: { id: props.currentID } }).then((res) => {
          const relates = (res.data || []).map((item: any) => ({ id: item.lawVersionId, name: item.lawName }))
          if (referenceLaws.value.length == 0) {
            referenceLaws.value = relates
            referenceLawsTotal.value = relates.length
          } else {
            relates.forEach((item: any) => {
              referenceLaws.value.push(item)
            })
          }
          emit('match-link', referenceLaws.value)
        })
          .catch((err) => {
            console.log(err)
          })
      }

    }
    const referenMoreClick = () => {
      curPage2.value++
      referenceLawsApi(defaultValue.value)
    }
    watch(
      () => props.currentID,
      () => {
        RelatedLawsApi(defaultValue.value * 2)
        referenceLawsApi(defaultValue.value)
      }
    )
    const handleClick = (item) => {
      if (!item.link || process.env.VUE_APP_HIDE_TOOL == 1) {
        ElMessage.warning('建设中')
      } else {
        window.open(item.link)
      }
    }
    return {
      RelatedLaws,
      referenceLaws,
      relateMoreClick,
      referenMoreClick,
      RelatedLawsTotal,
      tools,
      referenceLawsTotal,
      handleClick,
      REQUIRE_LOGIN:process.env.VUE_APP_REQUIRE_LOGIN
    }
  }
})
