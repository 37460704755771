
import { compile, computed, defineComponent, reactive } from 'vue'
import TagPanel from './TagPanel.vue'
import httpRequest from '@/service'
import { ElMessage } from 'element-plus/lib/components'
import { jumpFn } from '../../utils'
import { useRouter } from 'vue-router' // 详情页链接跳转新页面

export default defineComponent({
  name: 'SubRules',
  components: {
    TagPanel
  },
  props: {
    isZywj: {},
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    pureId: {
      type: String,
      default: '0'
    }
  },
  setup() {
    const router = useRouter()
    const ruleCls = (item: any) => {
      let name = ''
      if (item.type && item.type !== 'tiao') name = 'title'
      if (item.type === 'tiao' || !item.type) name = 'tiao'
      return name
    }

    const cacheObj: { [index: string]: any } = {}
    const lawSummary = reactive({
      currentId: '',
      data() {
        return this.cache['law_' + this.currentId] || {
          lawVersionId: undefined,
          lawName: undefined,
          publishOrgName: undefined,
          timelinessName: undefined,
          lawNumber: undefined,
          effectLevelName: undefined,
          publishDate: undefined,
          implDate: undefined
        }
      },
      cache: cacheObj,
      loading() {
        return !this.cache['law_' + this.currentId]
      },
      loadData(lawVersionId: string) {
        if (!lawVersionId) {
          return
        }
        this.currentId = lawVersionId
        if (this.cache['law_' + lawVersionId]) {
          return
        }
        httpRequest
          .get({
            url: 'lawSummary',
            params: {
              lawVersionId: lawVersionId
            }
          })
          .then((res: any) => {
            this.cache['law_' + lawVersionId] = res.data
          })
          .catch((err) => {
            ElMessage({
              message: '法律摘要加载失败',
              type: 'error'
            })
          })
      },
      jump(lawVersionId: string) {
        jumpFn(lawVersionId, router)
      }
    })

    const dynamicContent = computed(() => (content: string) => {
      let dcontent = content.replaceAll(/(<span class="relate-link" data-id="([0-9]+?)">.*?<\/span>)/g, `
      <el-popover
        popper-class="hover-pop"
        :width="800"
        :show-arrow="false"
        placement="top"
        trigger="hover"
        @show="lawSummary.loadData('$2')">
        <template #reference>
          <span v-html='\`$1\`'></span>
        </template>
        <div
          class="hover-law-summary"
          v-loading="lawSummary.loading()"
          element-loading-background="rgba(0, 0, 0, 0)">
          <div class="law-summary-title" v-if="!lawSummary.loading()">
        <h3 @click="lawSummary.jump('$2')">
          {{ lawSummary.data().lawName || '法律标题' }}
        </h3>
          </div>
          <hr v-if="!lawSummary.loading()" />
          <div class="law-summary-properties" v-if="!lawSummary.loading()">
        <el-row>
          <el-col :span="14">
        <span class="law-summary-properties-label">发布机关</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().publishOrgName }}</span>
          </el-col>
          <el-col :span="10">
        <span class="law-summary-properties-label">时效性</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().timelinessName }}</span>
          </el-col>
          <el-col :span="14">
        <span class="law-summary-properties-label">发文字号</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().lawNumber }}</span>
          </el-col>
          <el-col :span="10">
        <span class="law-summary-properties-label">效力级别</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().effectLevelName }}</span>
          </el-col>
          <el-col :span="14">
        <span class="law-summary-properties-label">发布日期</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().publishDate }}</span>
          </el-col>
          <el-col :span="10">
        <span class="law-summary-properties-label">实施日期</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().implDate }}</span>
          </el-col>
        </el-row>
          </div>
        </div>
      </el-popover>
      `)
      return compile(dcontent)
    })

    return {
      ruleCls,
      dynamicContent,
      lawSummary
    }
  }
})
