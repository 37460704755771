
import { defineComponent, nextTick, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import SubMenus from './SubMenus.vue'
import SubRules from './SubRules.vue'
import { IComObj } from '@/global/types'
import { Key, matchStr } from '../../utils'

export default defineComponent({
  name: 'Rules',
  props: {
    notitle: {
      type: Boolean,
      default: false
    },
    title: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isZywj: {},
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    menu: {
      type: Array,
      default: () => {
        return []
      }
    },
    fv: {
      type: Array,
      default: () => {
        return []
      }
    },
    pureId: {
      type: String,
      default: '0'
    },
    matchIndex: {
      type: Number,
      default: 0
    }
  },
  components: {
    SubMenus,
    SubRules
  },
  setup(props: any) {
    const route = useRoute()
    const rulesRef = ref<Document>()
    const filterArticalValue = ref<string[]>([])
    const filterData = ref<IComObj>([])
    const filterMenuData = ref<IComObj>([])

    const filterTitle = ref({
      title: '',
      subTitle: ''
    })
    const flag = route.query.sourceFlag

    // 检索
    if (flag && props.fv.length) {
      filterArticalValue.value = [...props.fv]
    }

    // 文本匹配
    const matchArr = ref<IComObj[]>([])
    const curMatchIndex = ref(props.matchIndex)

    // 根据form中的searchArtical递归数据添加span标签
    const circulationFn = (data: any) => {
      if (data.length === 0 || filterArticalValue.value.length === 0) return
      data.forEach((o: any, index: number) => {
        if (!o.children) {
          o = matchStr(o, filterArticalValue.value, matchArr.value)
          data[index] = o
        } else if (o.name && o.children) {
          o.name = matchStr(o.name, filterArticalValue.value, matchArr.value)
          circulationFn(o.children)
        } else if (!o.name && o.children) {
          circulationFn(o.children)
        }
      })
    }

    watch(
      () => props.data,
      () => {
        if (props.title && props.title.title) {
          filterTitle.value = JSON.parse(JSON.stringify(props.title))
          filterTitle.value.title = matchStr(
            filterTitle.value.title,
            filterArticalValue.value,
            matchArr.value
          )
          if(filterTitle.value.subTitle){
            filterTitle.value.subTitle = matchStr(
              filterTitle.value.subTitle,
              filterArticalValue.value,
              matchArr.value
            )
          }
        }
        filterData.value = JSON.parse(JSON.stringify(props.data))
        filterMenuData.value = JSON.parse(JSON.stringify(props.menu))
        circulationFn(filterData.value)
        circulationFn(filterMenuData.value)
        nextTick(() => {
          matchNextDom()
          setSubTitleStyle()
        })
      },{
        deep:true
      }
    )

    watch(
      () => props.fv,
      (newValue) => {
        let keyDemo = new Key()
        keyDemo.setKey(1) // 每次检索重置key值
        filterData.value = JSON.parse(JSON.stringify(props.data))
        filterMenuData.value = JSON.parse(JSON.stringify(props.menu))
        filterTitle.value = JSON.parse(JSON.stringify(props.title))
        filterArticalValue.value = newValue

        if (filterTitle.value.title) {
          filterTitle.value.title = matchStr(
            filterTitle.value.title,
            filterArticalValue.value,
            matchArr.value
          )
          filterTitle.value.subTitle = matchStr(
            filterTitle.value.subTitle,
            filterArticalValue.value,
            matchArr.value
          )
        }
        circulationFn(filterMenuData.value)
        circulationFn(filterData.value)

        nextTick(() => {
          matchNextDom()
        })
      }
    )

    const matchNextDom = () => {
      // 每次重新检索关键词删除目录段高亮
      document.querySelectorAll('.menu-match-b').forEach((dom: any) => {
        dom.classList.remove('menu-match-b')
      })
      curMatchIndex.value = props.matchIndex
      const matchDoms = document
        .querySelector('.regulation-wrap')
        ?.querySelectorAll('b')
      const curMatchDom = document
        .querySelector('.regulation-wrap')
        ?.querySelectorAll('b')[curMatchIndex.value]
      matchDoms?.forEach((dom: any) => {
        dom.classList.remove('match-b')
      })
      if (curMatchDom) {
        curMatchDom.classList.add('match-b')
        document.documentElement.scrollTop = curMatchDom.offsetTop
      } else {
        if (route.query.paraId) {
          const returnEle: HTMLElement = document.getElementById(
            route.query.paraId as any
          ) as HTMLElement
          if (returnEle) {
            document.documentElement.scrollTop = returnEle.offsetTop
          }
        }
      }
    }
    const setSubTitleStyle = () =>{
      const dom:any = document.querySelector('.m-lawTitle > p')
      if(!dom) return;
      const height = dom.offsetHeight
      if(height > 30){
        dom.style.textAlign = 'left'
        dom.style.textIndent = '2em'
      }else{
        dom.style.textAlign = 'center'
        dom.style.textIndent = 0
      }
    }
    // 全文匹配上一个下一个
    watch(
      () => props.matchIndex,
      () => {
        matchNextDom()
      }
    )

    return {
      matchArr,
      rulesRef,
      filterData,
      filterTitle,
      filterMenuData
    }
  }
})
